<template>
  <div class="pageContainer targetPage flexCloumn cbox">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1">
        <el-input placeholder="企业名称" v-model="searchForm.firm_name" size="small" class="input-with-select"
          style="width:200px;" clearable></el-input>
        <el-select v-model="searchForm.status" clearable placeholder="请选择" size="small"
          style="width:130px;margin-left:10px">
          <el-option label="全部" value></el-option>
          <el-option label="未审核" :value="1"></el-option>
          <el-option label="审核通过" :value="2"></el-option>
          <el-option label="审核不通过" :value="3"></el-option>
        </el-select>
        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="() => { page.current_page = 1; getList()}">搜索</el-button>
      </div>

      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">
        <el-button size="small" icon="el-icon-download" @click="exportExcelDate" type="primary">导出汇总数据</el-button>
      </div>
    </el-row>

    <div class="dividerBar"></div>

    <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
      style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="65" align="center"></el-table-column>
      <el-table-column label="企业名称" prop="firm_name" show-overflow-tooltip width="200px"
        align="center"></el-table-column>
      <el-table-column label="企业联系人" prop="firm_principal" width="120" align="center"></el-table-column>
      <el-table-column label="联系电话" prop="tel" width="150px" align="center"></el-table-column>
      <el-table-column label="企业性质" prop="enter_type" width="200" align="center"></el-table-column>
      <el-table-column label="邮箱" prop="email" show-overflow-tooltip width="200" align="center"></el-table-column>
      <el-table-column label="企业地址" prop="address" show-overflow-tooltip width="250" align="center"></el-table-column>
      <el-table-column label="总需求人数" prop="total_num" width="120" align="center"></el-table-column>
      <el-table-column label="申请日期" prop="create_date" width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.create_date | handleDate }}
        </template>
      </el-table-column>
      <el-table-column label="审核状态" prop="status" width="100px" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.status == 1" type="warning">待审核</el-tag>
          <el-tag size="mini" v-if="scope.row.status == 2" style="color:green">审核通过</el-tag>
          <el-tag size="mini" v-if="scope.row.status == 3" style="color:red">审核不通过</el-tag>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <el-tag class="pointer" @click="() => {
            modelTitle = '查看';
            handleModify(scope.row)
          }" size="small">查看</el-tag>
          <el-tag class="pointer" type="success" v-if="scope.row.status === 1" @click="() => {
            modelTitle = '审核';
            handleModify(scope.row)
          }" size="small" style="margin-left:10px;">审核</el-tag>
          <el-tag v-if="isAdmin" class="pointer" type="danger" @click="handleDelete(scope.row)" size="small"
            style="margin-left:10px;">删除</el-tag>
          <!-- <el-tag class="pointer" v-if="scope.row.status > 0" @click="exportPDF(scope.row)" size="small"
            style="margin-left:10px;">导出PDF</el-tag> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" width="1200px"
      custom-class="cus_dialog cus_dialogMax">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;"> {{ modelTitle }}</span>
      </div>
      <div>
        <div class="companyInfo">
          <div class="exportBtn">
            <el-button size="small" v-print="'#printMe'" type="primary"
              v-if="companyDetail.status == 2">导出回执单</el-button>
          </div>
          <div class="" v-if="companyDetail.check_desc" style="width:960px; margin: 0 auto;">审核备注：<span
              style="color:orangered">{{ companyDetail.check_desc }}</span></div>
          <div id="printMe"
            style="padding: 20px; border-radius: 4px; text-align: center; width: 1000px; margin: 0 auto;">
            <div class="row" style="border: 1px solid #ccc; border-left: 0;">
              <div class="td" style="width: 100%;">
                <div style="width: 100%;">
                  <div v-if="$store.state.pmid != 220 || !headerInfo || !headerInfo.title">
                    <strong style="font-size: 18px;">{{ $store.state.config.schoolName_z }} 校园招聘会</strong>
                    <br />参会回执
                  </div>
                  <div v-if="$store.state.pmid == 220 && headerInfo && headerInfo.title">
                    <strong style="font-size: 22px">{{ headerInfo.title }}</strong>
                    <br /><span style="font-size: 20px">参会回执</span>
                  </div>
                  <div style="display: inline-block; float: right;">
                    <el-tooltip placement="top" :disabled="companyDetail.status == 3 ? false : true">
                      <div slot="content">{{ companyDetail.check_desc }}</div>
                      <span v-if="companyDetail.status && companyDetail.status == 1"
                        style="color: #0050D0; font-size: 12px;">未审核</span>
                      <span v-if="companyDetail.status && companyDetail.status == 2"
                        style="color: #009F95; font-size: 12px;">审核通过</span>
                      <span v-if="companyDetail.status && companyDetail.status == 3"
                        style="color: orangered; font-size: 12px;">审核不通过</span>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="$store.state.pmid == 220" class=" row">
              <div class="td" style="width: 15%">活动时间</div>
              <div class="td" style="width: 55%; text-align: left">
                {{ headerInfo.time }}
              </div>
              <div class="td" style="width: 15%">活动地点</div>
              <div class="td rltd" style="width: 15%">
                {{ headerInfo.address }}
              </div>
            </div>

            <div class="row">
              <div class="td" style="width: 15%;">单位名称</div>
              <div class="td" style="width: 55%; text-align: left;">{{ companyDetail.firm_name }}</div>
              <div class="td" style="width: 15%;">单位性质</div>
              <div class="td rltd" style="width: 15%;">{{companyDetail.enter_type || "-"}}</div>
            </div>
            <div v-if="$store.state.pmid != 220" class="row">
              <div class="td" style="width: 15%;">单位地址</div>
              <div class="td" style="width: 55%; text-align: left;">{{ companyDetail.address }}</div>
              <div class="td" style="width: 15%;">邮政编码</div>
              <div class="td rltd" style="width: 15%;">{{ companyDetail.postal_code }}</div>
            </div>
            <div v-if="$store.state.pmid == 220" class="row">
              <div class="td" style="width: 15%;">单位地址</div>
              <div class="td rltd" style="width: 85%; text-align: left;">{{ companyDetail.address }}</div>
            </div>
            <div v-if="$store.state.pmid != 220" class="row">
              <div class="td" style="width: 15%;">单位联系部门</div>
              <div class="td" style="width: 15%; text-align: left;">{{ companyDetail.contact_dept }}</div>
              <div class="td" style="width: 15%;">联系人</div>
              <div class="td" style="width: 25%;">{{ companyDetail.firm_principal }}</div>
              <div class="td" style="width: 15%;">电话</div>
              <div class="td rltd" style="width: 15%;">{{ companyDetail.tel }}</div>
            </div>
            <div v-if="$store.state.pmid == 220" class="row">
              <div class="td" style="width: 15%;">联系人</div>
              <div class="td" style="width: 35%;">{{ companyDetail.firm_principal }}</div>
              <div class="td" style="width: 15%;">电话</div>
              <div class="td rltd" style="width: 35%;">{{ companyDetail.tel }}</div>
            </div>
            <div v-if="$store.state.pmid != 220" class="row">
              <div class="td" style="width: 15%;">传 真</div>
              <div class="td" style="width: 15%; text-align: left;">{{ companyDetail.fax }}</div>
              <div class="td" style="width: 15%;">电子邮箱</div>
              <div class="td" style="width:25%;">{{ companyDetail.email }}</div>
              <div class="td" style="width: 15%;">参会人数</div>
              <div class="td rltd" style="width: 15%;">{{ companyDetail.num }}</div>
            </div>
            <div v-if="$store.state.pmid == 220" class="row">
              <div class="td" style="width: 15%;">电子邮箱</div>
              <div class="td rltd" style="width:85%;">{{ companyDetail.email }}</div>
            </div>
            <div class="row">
              <div class="td" style="width: 15%;height: auto!important;">企业简介</div>
              <div class="td rltd" style="width: 85%;text-align: left; display: inline-table; height: 136px; ">
                {{ companyDetail.job_desc }}
              </div>
            </div>
            <div class="row">
              <div class="td rltd" style="width: 100%;">参会代表情况</div>
            </div>
            <div v-if="$store.state.pmid != 220" class="row">
              <div class="td" style="width: 20%;">姓名</div>
              <div class="td" style="width: 20%;">性别</div>
              <div class="td" style="width: 20%;">职务</div>
              <div class="td" style="width: 20%;">办公电话</div>
              <div class="td rltd" style="width: 20%;position:relative">移动电话</div>
            </div>
            <div v-if="$store.state.pmid == 220" class="row">
              <div class="td" style="width: 25%;">姓名</div>
              <div class="td" style="width: 25%;">性别</div>
              <div class="td" style="width: 25%;">职务</div>
              <div class="td rltd" style="width: 25%;position:relative">移动电话</div>
            </div>
            <div v-if="$store.state.pmid != 220" class="row chdb" v-for="(item, idx) in dbList" :key="idx + 100">
              <div class="td" style="width: 20%; ">{{ item.name }}</div>
              <div class="td" style="width: 20%;">{{ item.sex == 1 ? "男" : item.sex == 2 ? "女" : "-" }}</div>
              <div class="td" style="width: 20%;">{{ item.job }}</div>
              <div class="td" style="width: 20%;">{{ item.officePhone }}</div>
              <div class="td rltd" style="width: 20%;">{{ item.mobilePhone }}</div>
            </div>
            <div v-if="$store.state.pmid == 220" class="row chdb" v-for="(item, idx) in dbList" :key="idx + 100">
              <div class="td" style="width: 25%; ">{{ item.name }}</div>
              <div class="td" style="width: 25%;">{{ item.sex == 1 ? "男" : item.sex == 2 ? "女" : "-" }}</div>
              <div class="td" style="width: 25%;">{{ item.job }}</div>
              <div class="td rltd" style="width: 25%;">{{ item.mobilePhone }}</div>
            </div>
            <div class="row">
              <div class="td rltd" style="width: 100%;">毕业生需求信息登记表</div>
            </div>
            <div class="row">
              <div class="td" style="width: 20%;">招聘职位</div>
              <div class="td" style="width: 20%;">所需人数</div>
              <div class="td" style="width: 15%;">专业要求</div>
              <div class="td" style="width: 15%;">学历要求</div>
              <div class="td" style="width: 15%;">外语要求</div>
              <div class="td rltd" style="width: 15%;">计算机要求</div>
            </div>
            <div class="row chdb" v-for="(item, idx) in gwList" :key="idx">
              <div class="td rltd" style="width: 20%;">{{ item.post }}</div>
              <div class="td rltd" style="width: 20%;">{{ item.num }}</div>
              <div class="td rltd" style="width: 15%;">{{ item.specialty }}</div>
              <div class="td rltd" style="width: 15%;">{{ item.culture }}</div>
              <div class="td rltd" style="width: 15%;">{{ item.foreignLanguage }}</div>
              <div class="td rltd" style="width: 15%;">{{ item.computer }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="companyDetail.status == 1 && modelTitle == '审核'" style="padding-bottom: 120px; padding-left: 100px;">

        <el-col :span="15" style="">
          <el-input v-model.trim="spword" size="small" placeholder="审核拒绝时请填写拒绝原因"></el-input>
        </el-col>
        <el-col :span="6" style="padding-left: 80px;">
          <el-button size="small" type="warning" @click="submitForm(3)">审核拒绝</el-button>
          <el-button size="small" type="primary" @click="submitForm(2)">审核通过</el-button>
        </el-col>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      searchForm: {
        status: "",
        firm_name: ""
      },
      tableData: [],
      dialogForm: false,
      showDisabled: false,
      companyDetail: {
        status: 0,
        check_desc: "",
        info: "",
        firm_name: "",
        address: "",
        postal_code: "",
        contact_dept: "",
        firm_principal: "",
        tel: "",
        fax: "",
        email: "",
        num: 1,
        job_desc: ""
      },
      dbList: [
        { name: "", sex: "", job: "", officePhone: "", mobilePhone: "" }
      ],
      gwList: [
        {
          post: "",
          num: "",
          specialty: "",
          culture: "",
          foreignLanguage: "",
          computer: ""
        }
      ],
      qyxz: {},
      xlyq: {},
      wyyq: {},
      jsjyq: {},
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      spword: "",
      modelTitle: '',
      printObj: {
        id: "printMe",
        popTitle: "",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
      },
      isAdmin: false,
      headerInfo: {},

    };
  },
  filters: {
    handleDate(value) {	// value是输入框的内容，也是要显示的内容
      if (value) {
        return value.substring(0, 10)
      }
    }
  },
  mounted() {
    this.getConfig()
    this.getComType().then(rr => {
      this.getList();
    });
    setTimeout(() => {
      if (this.$store.state.teacher.sys_roles) {
        this.isAdmin = this.$store.state.teacher.sys_roles.filter(r => r.role_code.indexOf('admin') >= 0).length > 0
      }
    },500)
  },
  methods: {
    getConfig() {
      if (this.$store.state.config && this.$store.state.config.schoolname) {
        let config = this.$store.state.config
        if (config.xxzph_header_info) {
          this.headerInfo = JSON.parse(config.xxzph_header_info)
        }
      } else {
        this.$http.post("/api/sch_configs").then(res => {
          let config = {}
          for (let k of res.data) {
            config[k.ktype] = k.kvalue
          }
          if (config.xxzph_header_info) {
            this.headerInfo = JSON.parse(config.xxzph_header_info)
          }
        });
      }
      // console.log(592, this.headerInfo)
    },
    getComType() {
      return new Promise(resolve => {
        this.$http
          .post("/api/sys_param_list", {
            param_type:
              "'ENTER_INFO','EDUACTION','ENGLISH_LEVEL','COMPUTER_LEVEL'",
            used: 0
          })
          .then(res => {
            let qyxz = {}; //企业性质
            let xlyq = {}; //学历类型
            let wyyq = {}; //外语要求
            let jsjyq = {}; //计算机要求
            for (let item of res.data) {
              if (item.param_type == "ENTER_INFO") {
                qyxz[item.param_value] = item.param_desc;
              }
              if (item.param_type == "EDUACTION") {
                xlyq[item.param_value] = item.param_desc;
              }
              if (item.param_type == "ENGLISH_LEVEL") {
                wyyq[item.param_value] = item.param_desc;
              }
              if (item.param_type == "COMPUTER_LEVEL") {
                jsjyq[item.param_value] = item.param_desc;
              }
            }
            this.qyxz = qyxz;
            this.xlyq = xlyq;
            this.wyyq = wyyq;
            this.jsjyq = jsjyq;
            resolve();
          });
      });
    },
    linkExportPage() { }, //导出回执单
    getList() {
      const loading = this.$globalLoading()

      let data = {};
      data.status = this.searchForm.status;
      data.firm_name = this.searchForm.firm_name;
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      this.$http.post("/api/zp_job_fair_list", data).then(res => {
        if (res.data.data) {
          res.data.data.map(item => {
            if (item.job_info_list) {
              let jobs = JSON.parse(item.job_info_list)
              item.total_num = jobs.reduce((pre,cur) => {
                return pre + Number(cur.num)
              },0)
            }
          })
          this.tableData = res.data.data;
        } else {
          this.tableData = []
        }
        this.page = res.data.page;
        loading.close()
      });
    },
    exportExcelDate() {
      let data = {};
      data.status = this.searchForm.status;
      data.firm_name = this.searchForm.firm_name;
      this.$http.post("/api/zp_job_fair_export", data).then(res => {
        if (res.data.url) {
          window.open(res.data.url);
        }
      });
    },
    exportPDF(row) {
      this.$message.error("功能开发中...")

    },
    handleModify(row) {
      this.$http
        .post("/api/zp_job_fair_from", { id: row.id })
        .then(res => {
          this.companyDetail = res.data;
          if (res.data.job_info_list) {
            this.gwList = res.data.job_info_list;
          }
          if (res.data.delegates) {
            this.dbList = res.data.delegates;
          }
          this.spword = ""
          this.dialogForm = true;
        });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    handleDelete(row) {
      this.$confirm("此操作不可恢复，确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$http.post('/api/zp_job_fair_delete', {id:row.id}).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
    submitForm(st) {
      if (st == 3 && !this.spword.trim()) {
        this.$message.error("请输入拒绝原因");
        return;
      }
      this.$confirm("确定审核?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        if (st == 3) {
          if (!this.spword) {
            this.$message.error("请输入拒绝原因");
            return;
          }
        }

        this.$http
          .post("/api/zp_job_fair_audit", {
            id: this.companyDetail.id,
            status: st,
            check_desc: this.spword,
            firm_name: this.companyDetail.firm_name
          })
          .then(res => {
            this.$message({
              type: "success",
              message: "审核成功!"
            });
            this.getList();
            this.dialogForm = false;
          });
      });
    }
  }
};
</script>
  
<style lang="less" type="text/less">
.row {
  border-bottom: 1px solid #ccc;
  width: 100%;
  min-height: 40px;
  text-align: center;
  display: flex;
  position: relative;
}

.td {
  border-left: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  vertical-align: top;
  min-height: 52px;
  line-height: 32px;
  word-break: break-all;
}

.rltd {
  border-right: 1px solid #ccc;
}
</style>
<style lang="less" type="text/less" scoped>
.companyInfo {
  margin-top: 15px;
  background-color: #ffffff;
  font-size: 14px;
  color: #303133;
  position: relative;

  .exportBtn {
   
    top: 20px;
    text-align: right;
    padding-right: 100px;
  
  }
}
</style>

<style media="print">
@page {
    size: auto;
    /* auto is the initial value */
    margin: 3mm;
    /* this affects the margin in the printer settings */
}
</style>